import React, { useContext, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'

import { DateRange } from '../components/dateRangePicker/DateRange'
import { SingleSelect } from '../components/select/SingleSelect'
import { MultiSelect } from '../components/select/MultiSelect'
import { Dummy } from '../components/select/Dummy'
import { DataContext } from '../context/ContextProvider'
import { Loading } from '../components/Loader/Loading'
import { LineCharts } from '../components/visualize/LineCharts'
import { DataTable } from '../components/visualize/DataTable'
import { FetchRenderData } from '../services/FetchRenderData'
import { parseData } from '../utils/parseData'
import { customSort } from '../utils/customSort'
import { ScatterCharts } from '../components/visualize/ScatterCharts'
import { AvailabilityChart } from '../components/visualize/AvailabilityChart'
import './styles/Page.css'


export const MainTab = () => {
    const {
        desire_position,
        isExtended,
        filters,
        markingCityDict,
        createDimensionsFilters,
        getHashingId, firstReload,
        firstReloadMT, setFirstReloadMT,
        selectedFilters, setSelectedFilters,
        getSourceStatus,
        handleMultiselect,
        getAccessTokenSilently,
        priceEtaMin, setPriceEtaMin,
        distanceBucketPriceEtaMin, setDistanceBucketPriceEtaMin,
        priceDistanceVehicle, setPriceDistanceVehicle,
        etaTimeVehicle, setEtaTimeVehicle,
        allRecordCountVsTime, setAllRecordCountVsTime,
        priceTimeVehicle, setPriceTimeVehicle
    } = useContext(DataContext)

    const timeoutId = useRef(null)
    const [spinning, setSpinning] = useState(false)
    const current_tab = useLocation().pathname
    const [isOpen, setIsOpen] = useState(true)
    const [isMarkingCity, setIsMarkingCity] = useState(false)
    const [isCompetitor02, setIsCompetitor02] = useState(false)
    const [isCompetitor01, setIsCompetitor01] = useState(false)
    const [isBoltHost, setIsBoltHost] = useState(false)

    const getDistanceBucketPriceEtaMinData = async (selectedFilters, axiosToken) => {
        try {

            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFilters })

            const res_json = await FetchRenderData(dimensionsFilters, selectedFilters.date_range, distanceBucketPriceEtaMin.data.fields, getAccessTokenSilently, true, 0, 0, axiosToken, firstReload)
            if (res_json === null || res_json === undefined) return;

            const sortedData = customSort(parseData(res_json), distanceBucketPriceEtaMin.data.series_field, "ASC")

            let rows = []
            let temp = {}

            for (let ele of sortedData) {
                if (!(ele[distanceBucketPriceEtaMin.data.series_field] in temp)) {
                    temp[ele[distanceBucketPriceEtaMin.data.series_field]] = {}
                    temp[ele[distanceBucketPriceEtaMin.data.series_field]] = { [distanceBucketPriceEtaMin.data.series_field]: ele[distanceBucketPriceEtaMin.data.series_field] }
                }
                temp[ele[distanceBucketPriceEtaMin.data.series_field]][`${ele.distance_bucket}_upfront_fare`] = ele.upfront_fare
                temp[ele[distanceBucketPriceEtaMin.data.series_field]][`${ele.distance_bucket}_vehicle_eta`] = ele.vehicle_eta

            }

            let i = 0
            for (let key in temp) {
                temp[key]["key"] = i
                rows.push(temp[key])
                i += 1
            }

            let columns = [
                { title: "Vehicle Category", dataIndex: distanceBucketPriceEtaMin.data.series_field, key: distanceBucketPriceEtaMin.data.series_field, width: 150 },
                { title: "Short", children: [{ title: "Price", dataIndex: "short_upfront_fare", key: "short_upfront_fare" }, { title: "ETA", dataIndex: "short_vehicle_eta", key: "short_vehicle_eta" }] },
                { title: "Medium", children: [{ title: "Price", dataIndex: "medium_upfront_fare", key: "medium_upfront_fare" }, { title: "ETA", dataIndex: "medium_vehicle_eta", key: "medium_vehicle_eta" }] },
                { title: "Long", children: [{ title: "Price", dataIndex: "long_upfront_fare", key: "long_upfront_fare" }, { title: "ETA", dataIndex: "long_vehicle_eta", key: "long_vehicle_eta" }] }
            ]

            setDistanceBucketPriceEtaMin((prev) => ({ ...prev, id: hashId, data: { ...prev.data, rows: rows, columns: columns } }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }


    const getChartData = async (selectedFilters, useDataHook, setDataHook, axiosToken) => {
        try {
            let dimensionsFilters = createDimensionsFilters({ currentTab: current_tab })
            let hashId = await getHashingId({ currentTab: current_tab, selectedFiltersHook: selectedFilters })

            const res_json = await FetchRenderData(dimensionsFilters, selectedFilters.date_range, useDataHook.data.fields, getAccessTokenSilently, true, 0, 0, axiosToken, firstReload)
            if (res_json === null || res_json === undefined) return;

            const sortedData = customSort(customSort(parseData(res_json), useDataHook.data.dimensions.series_field, "ASC"), useDataHook.data.dimensions.x.field, "ASC")

            setDataHook((prev) => ({ ...prev, id: hashId, data: { ...prev.data, chart_data: sortedData } }))

        } catch (error) {
            if (axios.isCancel(error)) {
                console.log('Request canceled:', error.message);
                return false
            } else {
                throw new Error(error)
            }
        }
    }


    useEffect(() => {
        if (selectedFilters.id !== priceEtaMin.id) {

            if (timeoutId.current) timeoutId.current.cancel("Previous request canceled");
            let axiosToken = axios.CancelToken.source()
            timeoutId.current = axiosToken

            setSpinning(() => true)

            setTimeout(async () => {
                try {
                    let results = await Promise.all([
                        getSourceStatus({ currentTab: current_tab, sourceStatusHook: allRecordCountVsTime, setSourceStatusHook: setAllRecordCountVsTime, axiosToken: axiosToken, isFirstReload: firstReload, selectedFiltersHook: selectedFilters }),
                        getDistanceBucketPriceEtaMinData(selectedFilters, axiosToken),
                        getChartData(selectedFilters, priceEtaMin, setPriceEtaMin, axiosToken),
                        getChartData(selectedFilters, priceDistanceVehicle, setPriceDistanceVehicle, axiosToken),
                        getChartData(selectedFilters, etaTimeVehicle, setEtaTimeVehicle, axiosToken),
                        getChartData(selectedFilters, priceTimeVehicle, setPriceTimeVehicle, axiosToken)
                    ])
                    if (!(results.includes(false))) setSpinning(() => false)
                    setFirstReloadMT(false)
                } catch (error) {
                    console.error(error)
                    throw new Error("Something Went Wrong")
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilters])

    useEffect(() => {
        let source = filters.find(option => option.key.value === "source");
        let city = filters.find(option => option.key.value === "city");
        
        if (source !== undefined) {
            setIsCompetitor02(source.options.some((option) => option.value === "Competitor02"))
            setIsCompetitor01(source.options.some((option) => option.value === "Competitor01"))
        }

        if (city !== undefined) {
            setIsMarkingCity(city.default.options.some((ele) => ele.value in markingCityDict))
        }
    }, [filters, markingCityDict])

    useEffect(()=> {
        if (window.location.hostname === "bolt.show") {
            setIsBoltHost(true)
        } else {
            setIsBoltHost(false)
        }

    }, [])

    return (
        <div className="page_section">
            <div className="side_shadow" style={ { minWidth: isExtended ? "257px" : "57px", overflow: "auto" } }></div>
            <div className="page">
                <div className="filter_container">
                    { desire_position.map((key, index) => {
                        if (key === null) {
                            return selectedFilters?.date_range?.startDate ? <DateRange key={ index } selectedFilterHook={ selectedFilters } setSelectedFilterHook={ setSelectedFilters } /> : <Dummy key={ index } value={ key } />
                        } else {
                            let option = filters.find(option => option.key.value === key);
                            if (option === undefined) {
                                return <Dummy key={ index } value={ key } />
                            } else {
                                if ((option?.mode && option.mode === "single") || key === 'city' || key === 'country_code_currency') {
                                    return <SingleSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                } else {
                                    return <MultiSelect key={ index } data={ option } handleFunc={ handleMultiselect } />
                                }
                            }
                        }
                    }) }
                </div>

                <div className="visualize">
                    <Loading spinning={ spinning } />
                    {isBoltHost === true && <>
                        <h2 className='notice_head' style={ { display: isCompetitor01 || isCompetitor02 || isMarkingCity ? "block" : "none" } }>IMPORTANT
                            <span
                                style={ { display: "block", marginTop: isOpen ? "0px" : "3px", fontSize: "16px" } }
                                onClick={ () => setIsOpen(!isOpen) }
                            >
                                { isOpen ? "▲" : "▼" }
                            </span>
                        </h2>
                        <div className={ `notice ${isOpen ? "show" : ""}` } style={ { display: isCompetitor01 || isCompetitor02 || isMarkingCity ? "block" : "none" } }>
                            <div style={ { display: isCompetitor01 ? "block" : "none" } }>
                                <ul>
                                    <li><span>Competitor01</span> scraper is being investigated and therefore search price data cannot be consumed. Possible solution time is not yet known.</li>
                                    <li>For more detailed information please visit <span className='tag'>project-motor-launch</span> channel  or contact <span className='tag'>lauri.kaljurand@bolt.eu</span> in slack.</li>
                                </ul>
                            </div>
                            <div style={ { display: isCompetitor02 ? "block" : "none" } }>
                                <p>
                                    When looking  <span>Competitor02</span> data please bare in mind following:
                                </p>
                                <ul>
                                    <li> <span>Competitor02</span> pricing is based on bidding.</li>
                                    <li>If you do not see ETA for  <span>Competitor02</span> then we have been able to only record suggested fare given by the  <span>Competitor02</span> app -{ '>' } Bidding process is not done by the scraper.</li>
                                    <li>In some cases when ETA is displayed then this means we are able to mimic the bidding process.</li>
                                    <li>Price which is shown based on bidding process needs to be looked differently then price we have for apps which do not have bidding model:</li>
                                    <li>Price for non-bidding apps can still be rejected by drivers.</li>
                                    <li>Price for  <span>Competitor02</span> after bidding is price which driver will accept.</li>
                                    <li>This means that  <span>Competitor02</span> price might slightly above our own price.</li>
                                </ul>
                            </div>
                            <div style={ { display: isMarkingCity ? "block" : "none" } }>
                                <p> Please notice that for this city we have started to apply new route selection logic starting from <span className='tag'>{
                                    markingCityDict[filters.find(option => option.key.value === "city")?.default.options[0].value]?.split(" ")[0].trim()
                                } </span>:</p>
                                <ul>
                                    <li>We use Bolt internal real pick-up and drop-off points to define routes. Our data science team has also added noise meaning that they randomly shift points within 100 meters of their original location { '->' } this is needed for data privacy concerns</li>
                                    <li>We distribute rides in accordance with real Bolt rides database { '->' } this means we will have more extractions during peak hours and proportionally less in other hours.</li>
                                    <li>Routes are distributed between distance buckets based on the real distribution. We are not using anymore 30-40-30 split.</li>
                                </ul>
                            </div>
                        </div>
                    </>}
                    <div className="chart_container2">
                        <AvailabilityChart data={ allRecordCountVsTime.data } firstRender={ firstReloadMT } />
                    </div>
                    <div className='chart_container' style={ { width: "100%", display: "flex", gap: "15px" } }>
                        <div style={ { width: "50%", height: "100%" } } >
                            <ScatterCharts data={ priceEtaMin.data } firstRender={ firstReloadMT } />
                        </div>
                        <div style={ { width: "50%", height: "100%" } } >
                            <DataTable data={ distanceBucketPriceEtaMin.data } className={ "half_table" } firstRender={ firstReloadMT } />
                        </div>
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ priceDistanceVehicle.data } firstRender={ firstReloadMT } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ etaTimeVehicle.data } firstRender={ firstReloadMT } />
                    </div>
                    <div className="chart_container">
                        <LineCharts data={ priceTimeVehicle.data } firstRender={ firstReloadMT } />
                    </div>
                </div>
            </div>
        </div>
    )
}